import {
  props,
  on,
  createReducer,
  createAction,
  createSelector,
} from '@ngrx/store';
import _get from 'lodash-es/get';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';

import { IState, IBanner, IBannersState, IBannerFrequencyType } from '@models';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

export const BannersRequestAction = createAction('BANNERS_REQUEST');
export const BannersSuccessAction = createAction(
  'BANNERS_SUCCESS',
  props<{ banners: IBanner[]; frequency: number }>()
);
export const BannersErrorAction = createAction(
  'BANNERS_ERROR',
  props<{ error: string }>()
);
export type ActionsUnion =
  | typeof BannersRequestAction
  | typeof BannersSuccessAction
  | typeof BannersErrorAction;

export const adapter: EntityAdapter<IBanner> = createEntityAdapter<IBanner>({
  selectId: (entity: IBanner) => entity.BannerId,
});

export const initialState: IBannersState = adapter.getInitialState({
  frequency: 15,
});

export const BannersReducer = createReducer(
  initialState,
  on(BannersSuccessAction, (state, { banners, frequency }) => ({
    ...state,
    ...adapter.setAll(banners, state),
    frequency,
  }))
  // on(logoutAction, () => initialState),
);

const { selectAll } = adapter.getSelectors((state: IState) => state.banners);

export const selectBanners = selectAll;

const filterFutureBanners = (banner: IBanner) =>
  isBefore(new Date(banner.StartDate), new Date());
const filterEndedBanners = (banner: IBanner) =>
  banner.EndDate ? isAfter(new Date(banner.EndDate), new Date()) : true;
const filterHiddenByTypeBanners = (
  banner: IBanner,
  type: IBannerFrequencyType
) => banner.Frequencies[type] !== null;

export const selectOverallFrenquency = () => 10;
export const selectRunningBanners = createSelector(
  selectAll,
  (_state, type: IBannerFrequencyType) => type,
  (all = [], type) => {
    const filteredBanners = all
      .filter(filterFutureBanners)
      .filter(filterEndedBanners)
      .filter((banner) => filterHiddenByTypeBanners(banner, type));
    return filteredBanners;
  }
);

export default BannersReducer;

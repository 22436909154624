import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IBanner, IBannerFrequencyType, IState } from '@models';
import { select, Store } from '@ngrx/store';
import {
  selectOverallFrenquency,
  selectRunningBanners,
} from '@store/reducers/banners';
import { Observable } from 'rxjs';

import { BannerComponent } from './banner.component';
import { Platform } from '@ionic/angular/standalone';

@Component({
  selector: 'cs-banners',
  standalone: true,
  imports: [CommonModule, BannerComponent],
  template: `
    <!-- if native , do not display this component to pass store reviews -->
    <ng-container *ngIf="!platform.is('capacitor')">
      <ng-container *ngIf="banners$ | async as banners">
        <div *ngIf="banners.length">
          <ng-container
            *ngFor="let banner of banners; index as i; trackBy: trackBy"
          >
            <cs-banner
              [banner]="banner"
              *ngIf="shouldDisplay(banners.length, banner, i)"
            />
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class BannersComponent {
  @Input() type: IBannerFrequencyType;
  @Input() index: number;
  public banners$: Observable<IBanner[]> = this.store.pipe(
    select((state) => selectRunningBanners(state, this.type))
  );
  public overallFrenquency$: Observable<number> = this.store.pipe(
    select(selectOverallFrenquency)
  );
  constructor(protected store: Store<IState>, public platform: Platform) {}

  trackBy(_i, item: IBanner) {
    return item.BannerId;
  }

  shouldDisplay(nbrOfBanners: number, banner: IBanner, i) {
    if (nbrOfBanners > 1) {
      const overallFrequency = 3;
      const moduloBetweenItems = this.index % overallFrequency === 0;
      if (!moduloBetweenItems) return false;

      const moduloBetweenBanners =
        (i - this.index / overallFrequency) % nbrOfBanners === 0;
      return moduloBetweenBanners && moduloBetweenItems;
    }
    const frequency = banner.Frequencies[this.type];
    return this.index % frequency === 0;
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { IBanner } from '@models';
import { AnalyticsService } from '@shared/services/analytics.service';

@Component({
  selector: 'cs-banner',
  standalone: true,
  imports: [CommonModule],
  template: `
    <img loading="lazy" [src]="banner.ImageUrl" (click)="onClick()" />
  `,
  styles: [
    `
      :host {
        display: block;
        text-align: center;
      }
      img {
        cursor: pointer;
      }
    `,
  ],
})
export class BannerComponent implements OnInit {
  @Input() banner: IBanner;
  @Input() index: number;
  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit() {}

  onClick() {
    this.analyticsService.adsClick('Banner', this.banner.BannerId);
    // we do not want to open this in app but use the default browser
    window.open(this.banner.RedirectUrl, '_blank', 'location=yes');
  }
}
